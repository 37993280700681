<template>
  <div class="sidebar">
    <div class="userinfo">
      <div class="headImage pic">
        <img :src="headImage" alt="" class="i">
      </div>
      <div class="nickname">{{ nickname }}</div>
      <div class="studyno">
        <div class="txt">学号：{{ studyNo }}</div>
        <div class="btncopy pic" @click="copyStudyNo($event, studyNo)">
          <img src="@/assets/img/icon_009.png" alt="" class="i">
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="tab-bar">
      <div class="tab-term" :class="{cur: curMent == ind+1}" v-for="item, ind in menuList" :key="ind" @click="clickTabTrem(ind + 1)">
        <div class="icon pic">
          <img :src="item.icon" alt="" class="i">
          <img :src="item.iconH" alt="" class="i h">
        </div>
        <div class="txt">{{ item.txt }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="qr-code">
      <div class="pic">
        <img src="@/assets/img/qrcode-ycxt_258.jpg" alt="" class="i">
      </div>
      <div class="txt">易橙学堂公众号</div>
    </div>
  </div>
</template>

<script>
import copyText from '@/utils/clipboard' // use clipboard directly
import { mapGetters } from "vuex";
export default {
  props: {
    curMent: {
      type: String | Number,
      default: ''
    },
  },
  computed: {
    ...mapGetters([
      'nickname',
      'headImage',
      'studyNo',
    ]),
  },
  data () {
    return {
      menuList: [
        { icon: require('@/assets/img/icon_011.png'), iconH: require('@/assets/img/icon_011_h.png'), txt: '个人信息' },
        { icon: require('@/assets/img/icon_012.png'), iconH: require('@/assets/img/icon_012_h.png'), txt: '我的课程' },
      ],
    }
  },
  methods: {
    // 复制学号
    copyStudyNo(event, txt){
      copyText(txt, event);
    },
    // 菜单切换
    clickTabTrem(num){
      this.$router.push({query: {curMent: num + ''}});
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./sideBar.scss";
</style>
