<template>
  <div class="package-list">
    
    <template v-if="!(curPackageList && curPackageList.length)">
      <!-- 暂无课程 -->
      <div class="no-course">
        <div class="pic">
          <img src="@/assets/img/pic_002.png" alt="" class="i">
        </div>
        <div class="txt">暂无课程</div>
      </div>
    </template>

    <template v-else>
    <div class="card" :class="{disable: (item.dakeStatus && item.dakeStatus == 7) || item.status == 4}" v-for="item,ind in curPackageList" :key="ind" @click="btnItemCard(item)">
      <div class="tit">{{ item.title }}</div>
      <div class="time">课程周期：{{ item.courseCycle }}</div>
      <!-- 1. 签署合同, 2. 补充收货地址, 3. 选择开课时间, 4. 待开课, 5. 上课中,  6. 已结课,  7. 已过期 -->
      <div class="down">
        <template v-if="item.dakeStatus && item.dakeStatus == 1">
          <div class="btn">签署合同</div>
        </template>

        <template v-if="item.dakeStatus && item.dakeStatus == 2">
          <div class="btn">补充收货地址</div>
        </template>

        <template v-if="item.dakeStatus && item.dakeStatus == 3">
          <div class="btn">选择开课时间</div>
        </template>

        <template v-if="(item.dakeStatus && item.dakeStatus == 4) || item.status == 1">
          <div class="txt" :class="{['color00' + item.status]: true}">待开课</div>
          <div class="btn array">进入</div>
        </template>
        
        <template v-if="(item.dakeStatus && item.dakeStatus == 5) || item.status == 2">
          <div class="txt" :class="{['color00' + item.status]: true}">上课中</div>
          <div class="btn array">进入</div>
        </template>

        <template v-if="(item.dakeStatus && item.dakeStatus == 6) || item.status == 3">
          <div class="txt" :class="{['color00' + item.status]: true}">已结课</div>
          <div class="btn array">进入</div>
        </template>

        <template v-if="(item.dakeStatus && item.dakeStatus == 7) || item.status == 4">
          <div class="txt" :class="{['color00' + item.status]: true}">已过期</div>
          <div class="btn array">进入</div>
        </template>
      </div>

      <!-- 蒙层 -->
      <div class="mark"></div>
    </div>
    </template>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      'curPackageList',
    ]),
  },
  data(){
    return {
      
    }
  },
  created (){
    console.log('packageList');
    this.$store.dispatch("app/courseBreadcrumb", [{ label: '我的课程' }]);
  },
  methods: {
    btnItemCard(item){
      this.$emit('btnItemCard', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.package-list{
  margin: 35px 0 0 -30px;
  .card{
    margin: 15px 0 0 30px;
    display: inline-block; vertical-align: middle; width: calc(50% - 30px); box-sizing: border-box;
    padding: 34px 28px 25px;
    background: #F9F9F9; border-radius: 8px; border: 1px solid #ECECEC; cursor: pointer;
    transition: all .3s;
    &:hover{
      background: #FFFFFF; box-shadow: 0px 10px 20px 0px rgba(107,107,107,0.1); border: 1px solid #FFB997;
    }
    .mark{ display: none; }
    &.disable{
      position: relative; cursor: no-drop;
      &:hover{
        background: #F9F9F9; box-shadow: initial; border: 1px solid #ECECEC;
      }
      .mark{
        display: block;
        position: absolute; top: 0; left: 0; right: 0; bottom: 0;
        background-color: rgba(#F9F9F9, .5);
      }
    }
  }
  .tit{ font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
  .time{ font-size: 14px; color: #7C7C7C; line-height: 20px; margin-top: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
  .down{
    margin-top: 22px;
    display: flex; align-content: center; justify-content: space-between;
    .txt{
      font-size: 14px;
      &.color001{ color: #FF5D31; }
      &.color002{ color: #5DAB36; }
      &.color003{ color: #767676; }
      &.color004{ color: #B7B7B7; }
    }
    .btn{
      padding: 0 15px;
      height: 32px; background: #FF6315; border-radius: 6px;
      font-size: 14px; font-weight: 600; color: #FFFFFF; line-height: 32px;
      position: relative;
      &.array{
        padding-left: 20px; padding-right: 30px;
        &::after{
          position: absolute; right: 18px; top: 50%; transform: translateY(-50%); content: '';
          width: 6px; height: 11px; background: url(./../../../assets/img/icon_018.png) no-repeat 100%/100%;
        }
      }
    }
  }
  // 暂无课程
  .no-course{
    text-align: center; margin-top: 200px; margin-bottom: 200px;
    .pic{
      width: 205px; display: inline-block;
    }
    .txt{
      margin-top: 26px;
      font-size: 16px; color: rgba(#666666, .65);
    }
  }
}
</style>