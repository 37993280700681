<template>
  <div style="border: 1px solid #ccc" class="editor">
    <!-- 工具栏 -->
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
    />
    <!-- 编辑器 -->
    <Editor
      :style="{height: height}"
      :editor="editor"
      v-model="html"
      :defaultConfig="editorConfig"
      @onChange="onChange"
      @onCreated="onCreated"
    />
    <!-- 隐藏的dom容器 -->
    <div v-show="false" v-html="html" ref="boxElem"></div>
  </div>
</template>

<script>

import { Boot } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import formulaModule from "@wangeditor/plugin-formula";
import { upload } from "@/utils/oss";
Boot.registerModule(formulaModule);

export default {
  props: {
    contxt: { // 接收父组件需要显示内容
      type: String,
      default: () => ""
    },
    myIndex: {  // 接受一个下标
      type: Number
    },
    height: {  // 接受一个下标
      type: String,
      default: '500px'
    },
  },
  name: "MyEditor",
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
        insertKeys: {   // 公式使用 LateX 语法，使用 KateX 工具来渲染
          index: 0,
          keys: [
            "insertFormula", // “插入公式”菜单
            // 'editFormula' // “编辑公式”菜单
          ],
        },
      },
      editorConfig: {
        placeholder: "请输入内容...",
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},

        hoverbarKeys: {
          formula: {
            menuKeys: ["editFormula"], // “编辑公式”菜单
          },
        },
      },
    };
  },
  created () {

    let then = this;

    // 上传图片
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: this.$store.getters.uploadAccessoryFile, // 配置服务器端地址

      // form-data fieldName ，默认值 'wangeditor-uploaded-image'
      fieldName: 'file',

      // 单个文件的最大体积限制，默认为 2M
      maxFileSize: 5 * 1024 * 1024, // 5M

      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 1,

      // 超时时间，默认为 10 秒
      timeout: 60 * 1000, // 60 秒

      // 自定义上传(服务器地址、自动上传以及上传成功上传失败等方法失效)
      async customUpload(file, insertFn) {                   // JS 语法
        // file 即选中的文件
        // 自己实现上传，并得到图片 url alt href
        // 最后插入图片
        then.$store.dispatch('app/getBucket', { fileName:file.name }).then(res=>{
          upload(file, res.data.bucketname, res.data.domain, {
            onUploadProgress: (progressEvent) => {
              then.editor.showProgressBar(parseInt((progressEvent.loaded / progressEvent.total *100).toFixed(0)));  // 设置进度条进度
            }
          }).then(url => {
            insertFn(url);
          });

        });
      },

      // 自定义插入图片
      customInsert(res, insertFn) {                  // JS 语法
        // res 即服务端的返回结果
        console.log(res, insertFn)

        // 从 res 中找到 url alt href ，然后插入图片
        insertFn(res.data);
      },
    };
    // 上传视频
    this.editorConfig.MENU_CONF['uploadVideo'] = {
      server: this.$store.getters.uploadAccessoryFile, // 配置服务器端地址

      // form-data fieldName ，默认值 'wangeditor-uploaded-video'
      fieldName: 'file',

      // 单个文件的最大体积限制，默认为 10M
      maxFileSize: 1 * 1024 * 1024 * 1024, // 1024M

      // 最多可上传几个文件，默认为 5
      maxNumberOfFiles: 1,

      // 超时时间，默认为 30 秒
      timeout: 10 * 60 * 1000, // 10 分钟

      // 视频不支持 base64 格式插入

      // 自定义上传(服务器地址、自动上传以及上传成功上传失败等方法失效)
      async customUpload(file, insertFn) {                   // JS 语法
        // file 即选中的文件
        // 自己实现上传，并得到视频 url poster
        // 最后插入视频
        then.$store.dispatch('app/getBucket', { fileName:file.name }).then(res=>{
          upload(file, res.data.bucketname, res.data.domain, {
            onUploadProgress: (progressEvent) => {
              then.editor.showProgressBar(parseInt((progressEvent.loaded / progressEvent.total *100).toFixed(0)));  // 设置进度条进度
            }
          }).then(url => {
            insertFn(url, `${url}?x-oss-process=video/snapshot,t_0,f_jpg`); // 视频地址, 视频封面
          });

        });
      },

      // 自定义插入视频
      customInsert(res, insertFn) {                  // JS 语法
        // res 即服务端的返回结果

        // 从 res 中找到 url poster ，然后插入视频
        insertFn(res.data);
      },
    }
  },
  watch: {
    contxt:{
      handler (newValue) {
        this.html = newValue;
      },
      // deep: true,
      immediate: true,
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      // console.log(this.editor.getAllMenuKeys());
    },
    onChange(editor) {
      // console.log("onChange", "()", editor.getHtml(), "()", this.html, "()", editor.getText()); // onChange 时获取编辑器最新内容
      this.$nextTick(() => {
        let wrapElem = this.$refs.boxElem;
        if(!wrapElem) return;
        let span = wrapElem.getElementsByTagName('span');
        let elemArr = [];
        let valueArr = [];
        /* 报错, 先注释
        // span.forEach(element => {
        //   let spanName = element.getAttribute('data-w-e-type');
        //   let spanValue = element.getAttribute('data-value');
        //   if (spanName == 'formula' && spanValue) { // span 是公式 && span 有 data-value 且有值
        //     // console.log(element, '次数');
        //     elemArr.push(element);
        //     valueArr.push('$$' + spanValue + '$$');
        //   }
        // });
        */
        // console.log(elemArr, valueArr);
        elemArr.forEach((element, i) => {
          element.innerHTML =  valueArr[i];
        });

        // console.log(this.$refs.boxElem.innerHTML)

        this.$emit('change', this.$refs.boxElem.innerHTML, this.myIndex) // 将内容同步到父组件中
      })
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getText()); // 执行 editor API
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.w-e-full-screen-container{ z-index: 1; } /* 全屏菜单 */
.editor{
  ::v-deep .w-e-text-placeholder{ line-height: initial; }
  ::v-deep video{ max-width: 100%; }
}
</style>
