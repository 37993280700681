<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="dialog" :close-on-click-modal="false" :before-close="dialogClose" :destroy-on-close="true">
    <div class="dialog-content">
      <div class="header">
        <div class="close pic" @click="close">
          <img src="@/assets/img/icon_014.png" alt="" class="i">
        </div>
        <div class="title" v-if="btnCode == 1">设置登录密码</div>
        <div class="title" v-if="btnCode == 2">更改登录密码</div>
      </div>
      <div class="form">
        <el-form :model="formData" :rules="rules" ref="ruleForm">

          <div class="item">
            <pass-word :passWord.sync="formData.oldPassword" :propName="'oldPassword'" :rightIcon="true" style="width: 100%; margin-bottom: 20px;" v-if="btnCode == 2" :placeholderTxt="'输入旧密码'"></pass-word>
          </div>
          
          <div class="item">
            <pass-word :passWord.sync="formData.newPassword" :propName="'newPassword'" :rightIcon="true" style="width: 100%;" :placeholderTxt="btnCode == 2 ? '输入新密码' : '输入密码'"></pass-word>
          </div>

          <el-form-item style="margin-bottom: 0; margin-top: 35px;">
            <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">确认</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import mobile from "@/components/Input/mobile";
import verificationCode from "@/components/Input/verificationCode";
import passWord from "@/components/Input/passWord";
import { mapGetters } from "vuex";
import { changePassword } from '@/api/user' // 设置|更改密码
export default {
  components: {
    mobile, verificationCode, passWord
  },
  computed: {
    ...mapGetters([
      'mobile'
    ]),
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      
    },
  },
  data () {
    var checkPassword = (rule, value, callback) => {
      var reg_tel = /^(\w){6,20}$/;    // 6-20个字母、数字、下划线正则
      if (!value) {
        callback(new Error('输入密码'));
      } else if (!reg_tel.test(value)) {
        setTimeout(() => {
          callback(new Error('只能输入6-20个字母、数字、下划线'));
        }, 500);
      } else {
        callback();
      }
    };
    return {
      formData: {
        mobile: '',
        setType: '',  // 1 设置密码, 2 修改密码
        oldPassword: "",  // 旧密码
        newPassword: "",  // 新密码
      },
      dialogVisible: this.visible,
      rules: {
        oldPassword: [
          { required: true, validator: checkPassword, trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: checkPassword, trigger: 'blur' }
        ],
      },
      btnLoading: false,
      btnCode: '',  // 1绑定, 2更换, 1设置, 2修改
    }
  },
  mounted() {},
  watch: {
    visible (newValue){
      this.dialogVisible = newValue;
    },
    data (newValue){
      if(this.dialogVisible){
        this.btnCode = newValue.btnCode;
        this.formData.mobile = this.mobile;
        this.formData.setType = this.btnCode;
      }
    },
    options (newValue){
      
    },
  },
  methods: {
    // 确定 
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let p;
          if(this.btnCode == 1){
            p = changePassword({mobile: this.formData.mobile, setType: this.formData.setType, newPassword: this.formData.newPassword});
          } else if(this.btnCode == 2){
            p = changePassword({mobile: this.formData.mobile, setType: this.formData.setType, oldPassword: this.formData.oldPassword, newPassword: this.formData.newPassword});
          }
          p.then(res => {
            this.btnLoading = false;
            if(res.code == 0){
              this.$store.commit('user/SET_PASSWORD', this.formData.newPassword);  // 更新密码
              this.$emit('close', true, {});
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.dialogClose();
            }
          }).catch(err => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭
    close() {
      this.dialogClose();
    },
    // 弹窗关闭前回调
    dialogClose(){
      this.formData = { // 关闭清空弹窗数据
        mobile: '',
        setType: '',  // 1 设置密码, 2 修改密码
        oldPassword: "",  // 旧密码
        newPassword: "",  // 新密码
      },
      this.$refs['ruleForm'].resetFields(); // 对整个表单进行重置, 初始值
      this.$emit('close', false, {});
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{ display: none; }
::v-deep .el-dialog{
  width: 436px; position: relative;
  background: #FFFFFF; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
  border-radius: 20px; padding: 38px 44px 44px;
  .el-dialog__body{ padding: 0; }
  .header{
    .close{
      position: absolute; right: 22px; top: 18px;
      width: 20px; cursor: pointer;
    }
    .title{
      font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px;
    }
  }
  .form{
    margin-top: 20px;
  }
  .confirm{
    padding: 0;
    width: 100%; height: 54px; background: #FF6315; border-color: #FF6315; 
    font-size: 20px; font-weight: 600; color: #FFFFFF; line-height: 54px;
  }
}
</style>