<template>
  <el-form-item label="" :prop="propName" class="sex">
    <el-select v-model="value" placeholder="选择性别" @change="upDataValue">
      <el-option v-for="item in options" :key="item.code" :label="item.desc" :value="item.code"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  props: {
    sex: {
      type: String | Number,
      default: ''
    },
    propName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.sex,
      options: [
        { code: 1, desc: '男' },
        { code: 2, desc: '女' },
        { code: 0, desc: '保密' },
      ]
    }
  },
  methods: {
    upDataValue(){
      this.$emit('update:sex', this.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .sex{
    margin-bottom: 0;
    ::v-deep .el-select .el-input.is-focus .el-input__inner{
      border-color: #FF6315;
    }
    .el-select{ width: 100%; }

    ::v-deep .el-input__inner{
      height: 50px; line-height: 50px;
      border-radius: 14px; border: 1px solid #E5E5E5;
      font-size: 16px; color: #1B1B1B;
      &:focus{
        border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color:#E5E5E5;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#E5E5E5;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#E5E5E5;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#E5E5E5;
      }
    }
  }
  
</style>