<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="dialog" :close-on-click-modal="false" :before-close="dialogClose" :destroy-on-close="true">
    <div class="dialog-content">
      <div class="header">
        <div class="close pic" @click="close">
          <img src="@/assets/img/icon_014.png" alt="" class="i">
        </div>

        <div class="title">补充收获地址</div>
        <div class="description">请填写您的收货地址，我们将尽快为您安排课程教材发货</div>
      </div>
      <div class="form">
        <el-form :model="formData" :rules="rules" ref="ruleForm">

          <div class="item width50">
            <div class="label">收件人</div>
            <nick-name :nickName.sync="formData.realName" :propName="'realName'" :placeholderTxt="'输入收件人姓名'" style="width: 100%; margin-top: 4px;"></nick-name>
          </div>

          <div class="item width50">
            <div class="label">联系电话</div>
            <mobile :mobile.sync="formData.mobile" :propName="'mobile'" :placeholderTxt="'输入收件人联系电话'" style="width: 100%; margin-top: 4px;"></mobile>
          </div>

          <div class="item">
            <div class="label">所在地区</div>
            <area-dropdown :areaDropdown.sync="formData.areaDropdown" :propName="'areaDropdown'" style="width: 100%; margin-top: 4px;"></area-dropdown>
          </div>
          
          <div class="item">
            <div class="label">详细地址</div>
            <nick-name :nickName.sync="formData.addressDetail" :propName="'addressDetail'" :placeholderTxt="'输入详细地址'" style="width: 100%; margin-top: 4px;"></nick-name>
          </div>

          <el-form-item style="margin-bottom: 0; margin-top: 32px;">
            <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">确认提交</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import nickName from "@/components/Input/nickName";
import mobile from "@/components/Input/mobile";
import areaDropdown from "@/components/Input/areaDropdown";
import { mapGetters } from "vuex";
import { editAddress } from '@/api/course' // 补充收货地址
export default {
  components: {
    nickName, mobile, 
    areaDropdown
  },
  computed: {
    ...mapGetters([
      'mobile'
    ]),
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      
    },
  },
  watch: {
    ['formData.areaDropdown']: {
      handler(newValue){
        this.$set(this.formData, 'prove', newValue[0]);
        this.$set(this.formData, 'city', newValue[1]);
        this.$set(this.formData, 'area', newValue[2]);
      }
    },
    visible (newValue){
      this.dialogVisible = newValue;
    },
    data (newValue){
      if(this.dialogVisible){
        this.formData.packageId = newValue.packageId; 
      }
    },
    options (newValue){
      
    },
  },
  data () {
    var checkMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
      if (!value) {
        callback(new Error('输入手机号'));
      } else if (!reg_tel.test(value)) {
        // setTimeout(() => {
          callback(new Error('输入正确的手机号'));
        // }, 500);
      } else {
        callback();
      }
    };
    return {
      formData: {
        realName: '', // 收件人
        mobile: '', // 手机号
        areaDropdown: [],
        prove: '',  // 省
        city: '', // 市
        area: '', // 区
        addressDetail: '',  // 详细地址
        packageId: '',  // 
      },
      dialogVisible: this.visible,
      rules: {
        realName: [
          { required: true, message: '输入收件人姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        areaDropdown: [
          { required: true, message: '选择地区', trigger: 'change' },
        ],
        addressDetail: [
          { required: true, message: '输入详细地址', trigger: 'blur' }
        ],
      },
      btnLoading: false,
    }
  },
  mounted() {},
  methods: {
    // 确定 
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let p = editAddress({realName: this.formData.realName, mobile: this.formData.mobile, prove: this.formData.prove, city: this.formData.city, area: this.formData.area, addressDetail: this.formData.addressDetail, packageId: this.formData.packageId, isDefault: 0, directUse: 1});  // 最后两个参数为接口要求默认参数
          p.then(res => {
            this.btnLoading = false;
            if(res.code == 0){
              this.$emit('close', true, {});
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.dialogClose();
            }
          }).catch(err => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭
    close() {
      this.dialogClose();
    },
    // 弹窗关闭前回调
    dialogClose(){
      this.formData = { // 关闭清空弹窗数据
        realName: '', // 收件人
        mobile: '', // 手机号
        areaDropdown: [],
        prove: '',  // 省
        city: '', // 市
        area: '', // 区
        addressDetail: '',  // 详细地址
        packageId: '',  // 
      },
      this.$refs['ruleForm'].resetFields(); // 对整个表单进行重置, 初始值
      this.$emit('close', false, {});
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{ display: none; }
::v-deep .el-dialog{
  width: 650px; position: relative;
  background: #FFFFFF; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
  border-radius: 20px; padding: 30px 28px;
  .el-dialog__body{ padding: 0; }
  .header{
    .close{
      position: absolute; right: 22px; top: 18px;
      width: 20px; cursor: pointer;
    }
    .title{
      font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px;
    }
    .description{
      font-size: 14px; color: #808080; line-height: 20px; margin-top: 8px;
    }
  }
  .form{
    margin-top: 10px;
    .label{ font-size: 14px; color: #585858; line-height: 20px; }
    margin-left: -20px;
    .item{
      margin-top: 28px; margin-left: 20px;
      &.width50{
        display: inline-block; vertical-align: middle;
        width: calc(50% - 20px);
      }
    }
    .areaDropdown .el-input__inner, .nickName .el-input__inner{ height: 58px; line-height: 58px; }
  }
  .confirm{
    padding: 0; display: block; margin: auto;
    width: 350px; height: 54px; background: #FF6315; border-color: #FF6315; 
    font-size: 20px; font-weight: 600; color: #FFFFFF; line-height: 54px; border-radius: 14px;
  }
}
</style>