<template>
  <div class="course-list" v-loading="loading">

    <template v-if="!(optionsPc && optionsPc.length)">
      <!-- 暂无课程 -->
      <div class="no-course">
        <div class="pic">
          <img src="@/assets/img/pic_003.png" alt="" class="i">
        </div>
        <div class="txt">暂无直播计划</div>
      </div>
    </template>

    <template v-else>
    <div class="top">
      <div class="title">
        <div class="tit">{{ courseHead.batchName }}</div>
        <batch-select :batchId.sync="curBatchId" :options="[optionsPc]" style="width: 260px;" class="batch-select"></batch-select>
      </div>
      <div class="info-desc">
        <div class="icon-txt">
          <div class="icon pic">
            <img src="@/assets/img/icon_021.png" alt="" class="i">
          </div>
          <div class="txt">课程周期：{{ courseHead.liveStartAndEndTime }}</div>
        </div>
        <div class="icon-txt">
          <div class="icon pic">
            <img src="@/assets/img/icon_022.png" alt="" class="i">
          </div>
          <div class="txt">共{{ courseHead.readNode }}节｜ <span class="hover">已授{{ courseHead.haveNotNode }}节</span> </div>
        </div>
      </div>
    </div>
    <div class="line"></div>

    <template v-if="!(courseList && courseList.length)">
      <!-- 暂无课程 -->
      <div class="no-course">
        <div class="pic">
          <img src="@/assets/img/pic_004.png" alt="" class="i">
        </div>
        <div class="txt">暂无直播</div>
      </div>
    </template>

    <template v-else>
    <div class="tab-bar">
      <div class="tab-term" v-for="item, index in tabBar" :key="index" :class="{ cur: item.liveState == paramsCX.liveState }" @click="tabTrem(item, index)">{{ item.name }}</div>
    </div>
    <div class="list scroll-bar" v-infinite-scroll="listLoad" infinite-scroll-disabled="disabled" style="max-height: 1000px; overflow: auto;">
      <div class="item" v-for="item, ind in courseList" :key="ind">
        <div class="label">{{ item.liveTime }}</div>
        <!-- 直播课 -->
        <div class="content live">
          <div class="li">
            <div class="text">
              <div class="title">{{ item.liveName }}</div>
              <!-- 等待开始 || 直播中 -->
              <div v-if="item.liveState == 1 || item.liveState == 2" class="live-time">开始时间：{{ formatTime(Number(item.startTimeStamp)) }}</div>
              <!-- 观看回放 -->
              <div v-if="item.liveState == 3" class="live-time">直播时长：{{ item.showTime }}</div>
            </div>
            <el-popover placement="left" width="300" trigger="click" popper-class="app-qr-code-pop-up">
              <downloadAppPopUp></downloadAppPopUp>
              <div class="btn btn1" slot="reference" v-if="item.liveState == 3">观看回放</div>
              <div class="btn btn2" slot="reference" v-if="item.liveState == 2">
                <div class="pic">
                  <img src="@/assets/img/icon_024.png" alt="" class="i">
                </div>
                直播中
              </div>
              <div class="btn btn3" slot="reference" v-if="item.liveState == 1">等待开始</div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div v-if="listLoading" class="no-more">加载中...</div>
    <div v-if="noMore" class="no-more">没有更多了~</div>
    </template>

    </template>
    
  </div>
</template>

<script>
import { objectDeleteKey, formatTime } from "@/utils"
import { mapGetters } from "vuex";
import downloadAppPopUp from "@/components/downloadAppPopUp";
import batchSelect from "@/components/Input/batchSelect";
import { getLiveListApp, getBatchInfo, findLiveStatus } from "@/api/course"
export default {
  components: {
    downloadAppPopUp, batchSelect
  },
  computed: {
    ...mapGetters([
      'courseBreadcrumb'
    ]),
    noMore () {
      return this.courseList.length >= this.totalCount;
    },
    disabled () {
      return this.listLoading || this.noMore
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    courseBreadcrumb: {
      handler (newValue){
        console.log(newValue);
        if(newValue.length != 3) return;
        this.loading = true;
        let params = newValue[newValue.length - 1];
        this.paramsCX.packageId = params.packageId;
        this.paramsCX.campId = params.campId;

        // 查询批次
        getBatchInfo({ packageId: this.paramsCX.packageId, campId: this.paramsCX.campId }).then(res => {
          if(res.code == 0) {
            this.optionsPc = res.data.liveList ? res.data.liveList : [];
            if(this.optionsPc && this.optionsPc[0]){
              this.curBatchId = this.optionsPc[0].batchId;  // 默认选中第一个批次
            } else {
              this.loading = false;
            }
          }
        });

      },
      deep: true,
      immediate: true,
    },
    data (newValue){
      
    },
    // 批次改变
    curBatchId(newValue){
      this.loading = true;
      this.paramsCX.batchId = newValue;
      this.courseHead = this.optionsPc.filter(v => v.batchId == newValue)[0]; // 取到批次信息
      this.paramsCX.liveState = 0;
      
      // 查询列表
      this.paramsCX.pageNo = 1;
      let temO = JSON.parse(JSON.stringify(this.paramsCX));
      if(temO.liveState == 0){
        delete temO.liveState;
      }
      let p1 = getLiveListApp(objectDeleteKey(temO));

      // 查询直播状态
      let p2 = findLiveStatus({ packageId: this.paramsCX.packageId, campId: this.paramsCX.campId, batchId: this.paramsCX.batchId });

      Promise.all([p1, p2]).then(res => {
        if(res[0].code == 0 && res[1].code == 0){
          this.courseList = res[0].data.dataList;
          this.totalCount = res[0].data.totalCount;

          this.tabBar = res[1].data;
          this.tabBar.unshift({liveState: 0, name: '全部'});

          this.loading = false;
        }
      });
    },
  },
  data() {
    return {
      paramsCX: {
        packageId: '',
        campId: '',
        liveState: 0,
        batchId: '',
        pageNo: 1,
        pageSize: 10
      },  // 查询所用参数
      loading: false,
      courseHead: {}, // 头部信息
      courseList: [], // 列表信息
      totalCount: 0,  // 总条数
      optionsPc: [],  // 批次下拉
      curBatchId: '', // 当前选中的批次
      tabBar: [],  // 直播状态下拉
      listLoading: false,
    }
  },
  created (){
    console.log('courseListZB');
  },
  methods: {
    formatTime(num) {
      return formatTime(num);
    },
    // 切换课程类型
    tabTrem(item, index){
      if(this.paramsCX.liveState == item.liveState) return;
      this.paramsCX.liveState = item.liveState;
      this.loading = true;
      this.paramsCX.pageNo = 1;
      // 查询列表
      let temO = JSON.parse(JSON.stringify(this.paramsCX));
      if(temO.liveState == 0){
        delete temO.liveState;
      }
      let p1 = getLiveListApp(objectDeleteKey(temO));
      p1.then(res => {
        if(res.code == 0){
          this.courseList = res.data.dataList;
          this.totalCount = res.data.totalCount;
          this.loading = false;
        }
      });
    },
    listLoad () {
      this.listLoading = true;
      this.paramsCX.pageNo += 1;
      // 查询列表
      let temO = JSON.parse(JSON.stringify(this.paramsCX));
      if(temO.liveState == 0){
        delete temO.liveState;
      }
      let p1 = getLiveListApp(objectDeleteKey(temO));
      p1.then(res => {
        if(res.code == 0){
          this.courseList = this.courseList.concat(res.data.dataList);
          this.totalCount = res.data.totalCount;
          this.listLoading = false;
        }
      });
    }
  }
  
}
</script>

<style lang="scss" scoped>
@import "./courseListZB.scss";
</style>