<template>
  <el-select v-model="value" placeholder="课程状态" :popper-append-to-body="false" @change="upDataValue" class="valueZt">
    <el-option v-for="item in curPackageStatus" :key="item.status" :label="item.statusName" :value="item.status"></el-option>
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    valueZt: {
      type: String | Number,
      default: ''
    },
    propName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'curPackageStatus'
    ]),
  },
  data() {
    return {
      value: this.valueZt,
    }
  },
  methods: {
    upDataValue(){
      this.$emit('update:valueZt', this.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .valueZt{
    margin-bottom: 0;
    ::v-deep .el-select-dropdown__list{ padding: 5px !important; }
    ::v-deep .el-input__icon{
      line-height: 100%;
    }
    ::v-deep .popper__arrow{
      display: none;
    }
    ::v-deep .el-select-dropdown{ border: none; }
    .el-select-dropdown__item{ padding: 9px 8px; padding-right: 30px; font-size: 14px; color: #7C7C7C; }
    .el-select-dropdown__item + .el-select-dropdown__item{ margin-top: 4px; }
    .el-select-dropdown__item.selected{ background-color: transparent; color: #3C3C3C; }
    .el-select-dropdown__item.selected::after{ width: 12px; right: 8px; }

    ::v-deep .el-input__inner{
      height: 20px; line-height: 20px; padding-left: 0;
      font-size: 14px; color: #3C3C3C; border: none;
      &:focus{
        // border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color: #3C3C3C;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color: #3C3C3C;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color: #3C3C3C;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color: #3C3C3C;
      }
    }
  }
</style>