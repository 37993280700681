<template>
  <div class="app-container">
    <el-button type="primary" @click="btnDownload" style="margin-bottom: 20px;">下载</el-button>
    <el-button type="success" @click="btnPreview" style="margin-bottom: 20px;">预览</el-button>
    <wang-editor5 @change="changeHtml" :contxt="html" :height="'calc(100vh - 200px)'"></wang-editor5>
    <div v-html="html" class="scroll-bar preview" style="width: calc(100% - 20px); max-width: 375px; border: 1px #ddd solid; margin-top: 20px; padding: 8px;"></div>
  </div>
</template>
  
<script>
import wangEditor5 from '@/components/wangEditor5.1.22';  // 富文本编辑器
import { downloadF, openLink } from '@/utils'; // 文件下载, 打开一个链接
export default {
  name: "Contract",
  components: { 
    wangEditor5,
  },
  data() {
    return {
      html: localStorage.getItem('contractContent') ? localStorage.getItem('contractContent') :''
    };
  },
  mounted() {
    
  },
  methods: {
    // 内容改变后
    changeHtml(val){
      this.html = val;
      localStorage.setItem('contractContent', this.html);
    },
    // 下载
    btnDownload() {
      let fileContent = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>
  ${this.html}
</body>
</html>`
      downloadF(fileContent, 'index.html');
    },
    // 预览
    btnPreview() {
      openLink(`${window.location.origin}/contractView`, '_blank');
    },
  },
};
</script>

<style>
  body{ min-width: revert; }
</style>

<style lang="scss" scoped>
.app-container{ padding: 20px; }
::v-deep .preview p{
  margin: revert;
  padding: revert;
}
</style>