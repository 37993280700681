<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="dialog" :close-on-click-modal="false" :before-close="dialogClose" :destroy-on-close="true">
    <div class="dialog-content">
      <div class="header">
        <div class="close pic" @click="close">
          <img src="@/assets/img/icon_014.png" alt="" class="i">
        </div>

        <div class="title">打开微信扫码进行签署</div>
      </div>
      <div class="form">
        <div class="qrcode pic">
          <img :src="qrCodeUrl" alt="" class="i">
        </div>
        <div class="txt">因涉及到实名认证等操作，需要在手机端进行签署</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { findQrCodeUrl } from '@/api/course' // 获取签合同二维码
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      
    },
  },
  watch: {
    visible (newValue){
      this.dialogVisible = newValue;
    },
    data (newValue){
      if(this.dialogVisible){
        findQrCodeUrl({packageId: newValue.packageId}).then(res => {
          if(res.code == 0){
            this.qrCodeUrl = res.data;
          }
        });
      }
    },
    options (newValue){
      
    },
  },
  data () {
    return {
      dialogVisible: this.visible,
      qrCodeUrl: '',
      btnLoading: false,
    }
  },
  mounted() {},
  methods: {
    // 关闭
    close() {
      this.dialogClose();
    },
    // 弹窗关闭前回调
    dialogClose(){
      this.qrCodeUrl = '';
      this.$emit('close', true, {});
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{ display: none; }
::v-deep .el-dialog{
  width: 436px; position: relative;
  background: #FFFFFF; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
  border-radius: 20px; padding: 55px 60px;
  text-align: center;
  .el-dialog__body{ padding: 0; }
  .header{
    .close{
      position: absolute; right: 22px; top: 18px;
      width: 20px; cursor: pointer;
    }
    .title{
      font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px;
    }
  }
  .form{
    margin-top: 32px;
    .qrcode{
      padding: 0; box-sizing: border-box; margin: auto;
      width: 228px; height: 228px; background: #FFFFFF; box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
    }
    .txt{
      margin-top: 40px;
      font-size: 14px; color: #8D8D8D; line-height: 20px;
    }
  }
}
</style>