<template>
  <div class="app-container" v-html="html"></div>
</template>
  
<script>
export default {
  name: "ContractView",
  data() {
    return {
      html: localStorage.getItem('contractContent')
    };
  }
};
</script>

<style>
  body{ width: revert; min-width: revert; margin: 8px; }
</style>

<style lang="scss" scoped>
::v-deep p{
  margin: revert;
  padding: revert;
}
</style>