<template>
  <div class="course-list" v-loading="loading">
    <div class="top">
      <div class="title">
        <div class="tit">{{ courseHead.packageName }}</div>
        <!-- 直播计划 -->
        <div class="live-inlet" @click="toLive">
          <div class="icon pic">
            <img src="@/assets/img/icon_029.png" alt="" class="i">
          </div>
          <div class="txt">直播计划</div>
        </div>
      </div>
      <div class="info-desc">
        <div class="icon-txt">
          <div class="icon pic">
            <img src="@/assets/img/icon_021.png" alt="" class="i">
          </div>
          <div class="txt">课程周期：{{ courseHead.teachingStartTime }}——{{ courseHead.teachingEndTime }}</div>
        </div>
        <div class="icon-txt">
          <div class="icon pic">
            <img src="@/assets/img/icon_023.png" alt="" class="i">
          </div>
          <div class="txt">所属班级：{{ courseHead.campName }}{{ courseHead.className }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="tab-bar" v-if="tabBar && tabBar.length && tabBar[0].classificationId != 0">
      <div class="tab-term" v-for="item, index in tabBar" :key="index" :class="{ cur: index == courseIndex }" @click="tabTrem(item, index)">{{ item.classificationname }}</div>
    </div>
    <div class="list">
      <div class="item" :class="{ show: item.edit, hide: !item.edit}" v-for="item, index in curCourseList" :key="index">
        <div class="dt">
          <div class="text">
            <div class="title">
              <div class="tit">{{ item.courseName }}</div>
              <div class="icon pic" v-if="item.contentType == 1" style="width: 68px;">
                <img src="@/assets/img/icon_025.png" alt="" class="i">
              </div>
              <div class="icon pic" v-if="item.contentType == 2" style="width: 34px;">
                <img src="@/assets/img/icon_026.png" alt="" class="i">
              </div>
            </div>
            <div class="txt">共{{ item.numberOfNodes }}小节，已学习<span class="hover">{{ item.studyCount }}</span>节</div>
          </div>
          <div class="btn" @click="showHide(item, index, curCourseList)">
            {{ item.edit ? '收起' : '展开' }}
            <div class="pic" v-if="!item.edit">
              <img src="@/assets/img/icon_027.png" alt="" class="i">
            </div>
            <div class="pic" v-if="item.edit">
              <img src="@/assets/img/icon_028.png" alt="" class="i">
            </div>
          </div>
        </div>
        <div class="dd">
          <div class="child child-1" v-for="ite, ind in item.chapterList" :key="ind">
            <div class="child-t child-1-t">
              <div class="tit">{{ ite.chapterName }}</div>
            </div>
            <div class="child-c child-1-c">
              <div class="child child-2">
                <div class="child-t child-2-t" v-for="it, i in ite.sectionList ? ite.sectionList : []" :key="i">
                  <div class="tit">{{ it.sectionName }}</div>
                  <el-popover placement="left" width="300" trigger="click" popper-class="app-qr-code-pop-up">
                    <downloadAppPopUp></downloadAppPopUp>
                    <div class="btn btn1" slot="reference" v-if="it.lockStatus == 0">未解锁</div>
                    <template v-else>
                      <div class="btn btn3" slot="reference" v-if="it.hasStudy">已学习</div>
                      <div class="btn btn2" slot="reference" v-if="!it.hasStudy">去学习</div>
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-more">没有更多了~</div>
  </div>
</template>

<script>
import { uniqueFunc } from "@/utils";
import { mapGetters } from "vuex";
import downloadAppPopUp from "@/components/downloadAppPopUp";
import { findCourseByCustomerIdAndPackageId, getBigCourseInfo } from "@/api/course";
export default {
  components: {
    downloadAppPopUp
  },
  computed: {
    ...mapGetters([
      'courseBreadcrumb'
    ]),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    courseBreadcrumb: {
      handler (newValue){
        console.log(newValue);
        if(newValue.length != 2) return;
        this.loading = true;
        let params = newValue[newValue.length - 1];

        findCourseByCustomerIdAndPackageId({ packageId: params.packageId }).then(res => {
          if(res.code == 0) {
            this.courseHead = res.data.courseHead;
            this.allCourseList = res.data.bigCourseBos;

            this.tabBar = this.allCourseList.map(v => { return { classificationId: v.classificationId, classificationname: v.classificationname } }); // 取出 tabBar
            this.tabBar = uniqueFunc(this.tabBar, 'classificationId');  // 去重

            this.curCourseList = this.filterCourse(this.allCourseList, this.tabBar[this.courseIndex].classificationId);

            this.loading = false;
          }
        });
      },
      deep: true,
      immediate: true,
    },
    data (newValue){
      
    },
  },
  data() {
    return {
      loading: false,
      courseHead: {}, // 课程头部信息
      allCourseList: [],
      curCourseList: [],
      tabBar: [],
      courseIndex: 0,
    }
  },
  created (){
    console.log('courseListDK');
  },
  methods: {
    uniqueFunc(arr, uniId) {
      return uniqueFunc(arr, uniId);
    },
    // 展开收起
    showHide(item, index, arr){
      this.$set(arr[index], 'edit', !item.edit);
    },
    // 切换课程类型
    tabTrem(item, index){
      if(this.courseIndex == index) return;
      this.courseIndex = index;
      this.curCourseList = this.filterCourse(this.allCourseList, item.classificationId);
    },
    // 课程过滤
    filterCourse(allArr, uniId){
      let newArr = allArr.filter(v => v.classificationId == uniId);
      newArr && newArr.forEach((v, i) => {
        this.$set(newArr[i], 'edit', false);
        getBigCourseInfo({courseId: v.courseId}).then(res => {
          if(res.code == 0){
            this.$set(newArr[i], 'chapterList', res.data.chapterList);
          }
        });
      });
      return newArr;
    },
    // 进入直播
    toLive(){
      this.$store.dispatch("app/courseBreadcrumb", [this.courseBreadcrumb[0], this.courseBreadcrumb[1], { label: '直播计划', packageId: this.courseBreadcrumb[1].packageId, campId: this.courseBreadcrumb[1].campId } ]);
    },
  }
  
}
</script>

<style lang="scss" scoped>
@import "./courseListDK.scss";
</style>