<template>
  <div class="package-header">
    <div class="title">我的课程</div>
    <div class="screen" v-if="courseBreadcrumb.length == 1">
      <div class="item">
        <type-code :valueYw.sync="valueYw" style="width: 120px;"></type-code>
      </div>
      <div class="item">
        <course-status :valueZt.sync="valueZt" style="width: 100px;"></course-status>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import typeCode from "@/components/Input/typeCode";
import courseStatus from "@/components/Input/courseStatus";
export default {
  computed: {
    ...mapGetters([
      'courseBreadcrumb'
    ]),
  },
  components: {
    typeCode, courseStatus
  },
  props: {
    typeCode: {
      type: String | Number,
      default: ''
    },
    courseStatus: {
      type: String | Number,
      default: ''
    },
  },
  watch: {
    valueYw(newValue){
      this.$emit('update:typeCode', newValue);
    },
    valueZt(newValue){
      this.$emit('update:courseStatus', newValue);
    },
  },
  data(){
    return {
      valueYw: this.typeCode,
      valueZt: this.courseStatus,
    }
  }
}
</script>

<style lang="scss" scoped>
.package-header{
  padding-bottom: 12px;
  display: flex; align-items: center; justify-content: space-between;
  border-bottom: 1px solid rgba(#979797, .16);
  .title{
    font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px;
  }
  .screen{
    .item{
      display: inline-block; vertical-align: middle;
      &+.item{ margin-left: 40px; }
    }
  }
}
</style>