<template>
  <div class="info">
    <div class="tab-bar">
      <div class="tab-term" :class="{cur: curSelectTitle == item.code }" v-for="item in tabTitle" :key="item.code" @click="clickTabTrem(item.code)">{{ item.desc }}</div>
    </div>
    <div class="tab-cont">
      <div class="tab-item" v-if="curSelectTitle == 1">
        <div class="headImage">
          <div class="pic">
            <el-image style="width: 100%; height: 100%; display: block;" :src="formData.appHeadImage" :preview-src-list="[formData.appHeadImage]"></el-image>
          </div>
          <!-- <div class="btn" :disabled="uploadLoading">更换头像</div> -->
          <div style="display: inline-block; vertical-align: middle; margin-left: 15px;">
            <!-- 所有图片 image/*, image/gif -->
            <el-upload class="avatar-uploader" accept="image/png, image/jpg, image/jpeg" :action="''" :show-file-list="false" :on-change="handleChange" :auto-upload="false" :disabled="uploadLoading">
              <div class="btn" :disabled="uploadLoading">更换头像</div>
            </el-upload>
          </div>
        </div>

        <div class="form">
        <el-form :model="formData" :rules="rules" ref="ruleForm">

          <div class="item">
            <div class="label">昵称</div>
            <nick-name :nickName.sync="formData.appNickname" :propName="'appNickname'" :placeholderTxt="'输入昵称'" style="width: 375px; margin-top: 8px;"></nick-name>
          </div>
          
          <div class="item">
            <div class="label">性别</div>
            <sex :sex.sync="formData.sex" :propName="'sex'" style="width: 375px; margin-top: 8px;"></sex>
          </div>

          <div class="item">
            <div class="label">生日</div>
            <birthday :birthday.sync="formData.birthday" :propName="'birthday'" style="width: 375px; margin-top: 8px;"></birthday>
          </div>

          <div class="item">
            <div class="label">地区</div>
            <area-dropdown :areaDropdown.sync="formData.areaDropdown" :propName="'areaDropdown'" :children="2" style="width: 375px; margin-top: 8px;"></area-dropdown>
          </div>

          <el-form-item style="margin-bottom: 0; margin-top: 70px;">
            <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">保存</el-button>
          </el-form-item>

        </el-form>
        </div>
      </div>
      <div class="tab-item binding" v-if="curSelectTitle == 2">
        <div class="item" v-for="item, ind in binding" :key="ind">
          <div class="text">
            <div class="label">{{ item.label }}</div>
            <div class="isBind" v-if="ind == 0" :class="{ success: item.isBind, danger: !item.isBind }">{{ item.isBind ? '已绑定' : '未绑定' }}</div>
            <div class="isBind" v-if="ind == 1" :class="{ success: item.isBind, danger: !item.isBind }">{{ item.isBind ? '已设置' : '未设置' }}</div>
            <div class="txt">{{ item.content | mobileMark }}</div>
          </div>
          <div class="btn" v-if="ind == 0" @click="btnMobile(item.isBind ? 2 : 1)">{{ item.isBind ? '更换' : '绑定' }}</div>
          <div class="btn" v-if="ind == 1" @click="btnPassword(item.isBind ? 2 : 1)">{{ item.isBind ? '修改' : '设置' }}</div>
        </div>
      </div>
    </div>
    
    <!-- 绑定|更换手机号弹窗 -->
    <mobile-dialog :visible="visibleMobile" :data="item" @close="closeMobile" :options="[]"></mobile-dialog>
    <!-- 设置|修改密码弹窗 -->
    <password-dialog :visible="visiblePassword" :data="item" @close="closePassword" :options="[]"></password-dialog>

  </div>
</template>

<script>
import nickName from "@/components/Input/nickName";
import sex from "@/components/Input/sex";
import birthday from "@/components/Input/birthday";
import areaDropdown from "@/components/Input/areaDropdown";
import mobileDialog from "./mobileDialog";
import passwordDialog from "./passwordDialog";
import { mapGetters } from "vuex";
import { imageUpload, modifyAccountInfo } from "@/api/user";  // 上传头像, 编辑个人资料
export default {
  components: {
    nickName, sex, birthday, 
    areaDropdown,
    mobileDialog, passwordDialog
  },
  computed: {
    ...mapGetters([
      'nickname', 'headImage', 'sex', 'birthday', 'province', 'city', 
      'mobile', 'password', 
    ]),
  },
  watch: {
    ['formData.areaDropdown']: {
      handler(newValue){
        this.$set(this.formData, 'province', newValue[0]);
        this.$set(this.formData, 'city', newValue[1]);
      }
    },
    mobile: {
      handler(newValue){
        this.$set(this.binding[0], 'content', newValue);
      }
    }
  },
  filters: {
    mobileMark(value) {
      if(value){
        return value.slice(0, 3) + 'XXXX' + value.slice(7, 11);
      }
    }
  },
  data() {
    return {
      tabTitle: [
        { code: 1, desc: '个人资料' },
        { code: 2, desc: '账号安全设置' },
      ],
      binding: [
        { label: '验证手机：', isBind: false, content: '' },
        { label: '登录密码：', isBind: false, content: '' },
      ],
      visibleMobile: false,
      visiblePassword: false,
      item: {},
      curSelectTitle: 1,
      uploadLoading: false, // 上传头像 loading
      timeid: null, // 导入定时器
      speed: 0, // 导入进度
      formData: {  
        appHeadImage: "",
        appNickname: "",
        sex: "",
        birthday: "",
        areaDropdown: [],
        province: "",
        city: "",
      },
      rules: {
        appNickname: [
          { required: true, message: '输入昵称', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '选择性别', trigger: 'change' }
        ],
        birthday: [
          { required: true, message: '选择生日', trigger: 'change' },
        ],
        areaDropdown: [
          { required: true, message: '选择地区', trigger: 'change' },
        ],
      },
      btnLoading: false,
    }
  },
  created(){
    this.$set(this.formData, 'appHeadImage', this.headImage);
    this.$set(this.formData, 'appNickname', this.nickname);
    this.$set(this.formData, 'sex', this.sex);
    this.$set(this.formData, 'birthday', this.birthday);
    this.$set(this.formData, 'areaDropdown', [this.province, this.city]);
    this.$set(this.formData, 'province', this.province);
    this.$set(this.formData, 'city', this.city);
    this.$set(this.binding[0], 'isBind', this.mobile ? true : false);
    this.$set(this.binding[0], 'content', this.mobile);
    this.$set(this.binding[1], 'isBind', this.password ? true : false);
    
  },
  methods: {
    // 菜单切换
    clickTabTrem(num){
      this.curSelectTitle = num;
    },
    // 保存
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          modifyAccountInfo(this.formData).then(() => {
            this.btnLoading = false;
            this.$store.commit('user/SET_HEADIMAGE', this.formData.appHeadImage);
            this.$store.commit('user/SET_NICKNAME', this.formData.appNickname);
            this.$store.commit('user/SET_SEX', this.formData.sex);
            this.$store.commit('user/SET_BIRTHDAY', this.formData.birthday);
            this.$store.commit('user/SET_PROVINCE', this.formData.province);
            this.$store.commit('user/SET_CITY', this.formData.city);
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          }).catch(err => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击 绑定|更换 手机号
    btnMobile(code){
      this.visibleMobile = true;
      this.item = {btnCode: code};
    },
    closeMobile(is,obj){  // 关闭 - 
      this.visibleMobile = false;
      this.item = obj;
      if(is){
        
      }
    },
    // 点击 设置|修改 密码
    btnPassword(code){
      // 没绑定手机号, 请先绑定手机号 
      if(!this.mobile){
        this.$message({
          message: '请先绑定手机号',
          type: 'error'
        });
        return;
      }
      this.visiblePassword = true;
      this.item = {btnCode: code};
    },
    closePassword(is,obj){  // 关闭 - 
      this.visiblePassword = false;
      this.item = obj;
      if(is){
        
      }
    },
    // 文件状态改变
    handleChange(file,fileList){  
      console.log(file);

      this.uploadLoading = true;

      // 模拟上传进度
      let then = this;
      then.speed = 0;
      this.timeid = setInterval(() => {
        this.speed ++;
        if(this.speed >= 95) {
          clearInterval(this.timeid);
        }
      }, 10 * 60);

      const formData = new FormData();
      formData.append('file', file.raw);

      imageUpload(formData).then(res => {
        if(res.code == 0){
          this.formData.appHeadImage = res.data;
          this.uploadLoading = false;
          clearInterval(this.timeid);
          then.speed = 100;
        }
      }).catch(() => {
        this.uploadLoading = false;
        clearInterval(this.timeid);
        then.speed = 0;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./info.scss";


</style>