import Crypto from './libe';  // 阿里云oss签名所需的加密js文件

import axios from "axios";  // 基于 promise 的 HTTP 库

const Base64 = require('js-base64').Base64;
const config = {headers: {'Accept': 'application/json, text/javascript, */*; q=0.01'}}; // 处理请求跨域
const policyText = {
  "expiration": "2099-01-01T12:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
  "conditions": [
    ["content-length-range", 0, 1048576000] // 设置上传文件的大小限制
  ]
};

const data = {  // 阿里云 OSS 的配置
  accesskey:'kFRtiLbaTbUaR0XjfRgrSsdpsNg9Re',
  OSSAccessKeyId:'LTAI5tPF398yQ1jFjy3dHWf3'
}


/** 文件上传方法
  * @param file // 文件
  * @param bucketName // OSS 存储区域名
  * @param domain // 文件访问域名
  * @param uploadConfig // 请求配置对象
  **/
export function upload(file, bucketName, domain, uploadConfig = {}) {
  //******注意这三行是阿里云的签名加密******
  let policyBase64 = Base64.encode(JSON.stringify(policyText));
  let bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, data.accesskey, {asBytes: true});
  let signature = Crypto.util.bytesToBase64(bytes);

  //******请求阿里oss接口必传参数,注意必须是FormData格式******
  let multipart_params = formData({
    'key': `${getFileName(file.name)}`, // 文件存储路径
    'policy': policyBase64, // Policy表单域用于验证请求的合法性
    'success_action_status': 200, // 文件上传成功服务器返回的状态
    'OSSAccessKeyId': data.OSSAccessKeyId, 
    'signature': signature,
    'file': file
  });
  
  // process.env.VUE_APP_OSS_URL改为自己的上传地址
  return axios.post(`https://${bucketName}.oss-cn-hangzhou.aliyuncs.com/`, multipart_params, Object.assign(config, uploadConfig)).then((res) => {
    return `${domain}` + '/' + multipart_params.get('key') // 返回文件的绝对地址
  })
}

/** 对象转换为formData类型 */
function formData(obj) {
  if (obj instanceof Object) {
    let multipart_params = new FormData();
    for (let key in obj) {
      multipart_params.append(key, obj[key]);
    }
    return multipart_params;
  }
  return;
}

/** 生成随机文件名 格式为随机6位数+时间戳+文件类型 */
function getFileName(filename) {
  let pos = filename.lastIndexOf('.')
  if (pos != -1) {
    return randomString(6) + new Date().getTime() + filename.substring(pos);
  }
  return '';
}

/** 随机数 未指定长度则为32位 */
function randomString(len) {
  len = len || 32;
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  let maxPos = chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}