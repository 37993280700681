<template>
  <div class="package">
    
    <!-- 课程包头部 -->
    <package-header :typeCode.sync="typeCode" :courseStatus.sync="courseStatus"></package-header>

    <!-- 课程包列表 -->
    <package-list v-if="courseBreadcrumb.length == 1" v-loading="loading" :typeCode.sync="typeCode" :courseStatus.sync="courseStatus" @btnItemCard="btnItemCard"></package-list>

    <!-- 收货地址弹窗 -->
    <address-dialog :visible="visibleAddress" :data="item" @close="closeAddress" :options="[]"></address-dialog>

    <!-- 选择开课时间弹窗 -->
    <open-package-dialog :visible="visibleOpenCourse" :data="item" @close="closeOpenCourse" :options="[]"></open-package-dialog>

    <!-- 合同弹窗 -->
    <contract-dialog :visible="visibleContract" :data="item" @close="closeContract" :options="[]"></contract-dialog>

    <!-- 面包屑 -->
    <breadcrumb v-if="courseBreadcrumb.length > 1"></breadcrumb>

    <!-- 小白课程列表 -->
    <course-listXB v-if="courseBreadcrumb.length == 2 && courseBreadcrumb[1].courseType == 1" :data="{}"></course-listXB>

    <!-- 大课课程列表 -->
    <course-listDK v-if="courseBreadcrumb.length == 2 && courseBreadcrumb[1].courseType == 2" :data="{}"></course-listDK>

    <!-- 直播列表 -->
    <course-listZB v-if="courseBreadcrumb.length == 3" :data="{}"></course-listZB>

  </div>
</template>

<script>
import packageHeader from "./packageHeader";
import packageList from "./packageList";
import addressDialog from "./addressDialog";  // 收货地址弹窗
import openPackageDialog from "./openPackageDialog";  // 选择开课时间弹窗
import contractDialog from "./contractDialog";  // 合同弹窗
import breadcrumb from "./breadcrumb";  // 面包屑
import courseListXB from "./courseListXB";  // 小白课程列表
import courseListDK from "./courseListDK";  // 大课课程列表
import courseListZB from "./courseListZB";  // 直播列表
import { uniqueFunc } from "@/utils"; // 数据去重
import { mapGetters } from "vuex";
import { getMyCourse } from "@/api/course"; // 课程包列表
export default {
  components: {
    packageHeader, packageList, breadcrumb,
    addressDialog, openPackageDialog, contractDialog,
    courseListXB, courseListDK, courseListZB
  },
  computed: {
    ...mapGetters([
      'allPackageStatus',
      'courseBreadcrumb'
    ]),
  },
  watch: {
    typeCode(newValue){
      this.initData();
    },
    courseStatus(newValue){
      this.initData();
    },
  },
  data (){
    return {
      typeCode: '',
      courseStatus: '',
      loading: false,
      visibleAddress: false,  // 收货地址
      visibleOpenCourse: false,  // 选择开课时间
      visibleContract: false,  // 合同弹窗
      item: {},
    }
  },
  created(){
    console.log('package');

    // { label: '直播计划', packageId: '553930496025268985', campId: '685852986527232916' } // 直播计划测试参数
    // { label: '大课课程', packageId: '533305006155600543' } // 大课测试参数
    // { label: '小白直播课程', packageId: '553221610704179610', courseId: '552131660273390241', campId: '709466997156000589' } // 小白直播课测试参数
    // { label: '小白图文课程', packageId:'202008201946481588', courseId: '531847770749244411', campId: '673855267726209304' }  // 小白图文课测试参数
    // this.$store.dispatch("app/courseBreadcrumb", [{ label: '我的课程' }, { label: '小白图文课程', packageId:'202008201946481588', courseId: '531847770749244411', campId: '673855267726209304' }]);

    if(this.courseBreadcrumb && this.courseBreadcrumb.length < 1){
      this.$store.dispatch("app/courseBreadcrumb", [{ label: '我的课程' }]);
    }

    this.initData();

  },
  methods: {
    async initData(){
      this.loading = true;
      let p = await getMyCourse({});
      let allPackageList = p.data.dake ? p.data.dake.concat(p.data.xiaobai ? p.data.xiaobai : []) : p.data.xiaobai;

      // 业务下拉
      let packageTypeCode = allPackageList.map(v => { return { typeCode: v.typeCode, typeName: v.typeName } });

      // 状态下拉
      let hasStatus = allPackageList.filter(v => v.status); // 取出有 status 的数据
      let packageStatus = hasStatus.map(v => { return { status: v.status, statusName: v.xiaobaiStatusName ? v.xiaobaiStatusName : this.allPackageStatus.filter(j => j.code == v.status).length && this.allPackageStatus.filter(j => j.code == v.status)[0].desc } });

      this.$store.dispatch("app/allPackageList", allPackageList);
      this.$store.dispatch("app/curPackageList", this.filterPackageList(allPackageList, this.typeCode, this.courseStatus));

      this.$store.dispatch("app/curPackageStatus", [{ status: 0, statusName: '全部' }].concat(uniqueFunc(packageStatus, 'statusName')));
      this.$store.dispatch("app/packageTypeCode", [{ typeCode: 0, typeName: '全部' }].concat(uniqueFunc(packageTypeCode, 'typeCode')));
      this.loading = false;
    },
    filterPackageList(arr, code, status){
      return arr.filter(v => {
        if(code && status){
          return v.typeCode == code && v.status == status;
        } else if (code) {
          return v.typeCode == code;
        } else if (status) {
          return v.status == status;
        } else {
          return true;
        }
      });
    },
    // 点击某一个卡片
    btnItemCard(row){
      console.log(row);

      if(row.dakeStatus && row.dakeStatus == 1){
        this.visibleContract = true;  // 签合同
        this.item = row;
        return;
      }

      if(row.dakeStatus && row.dakeStatus == 2){
        this.visibleAddress = true;  // 填地址
        this.item = row;
        return;
      }

      if(row.dakeStatus && row.dakeStatus == 3){
        this.visibleOpenCourse = true;  // 选时间
        this.item = row;
        return;
      }

      // // 待开课, 上课中, 上课中
      if((row.dakeStatus && row.dakeStatus == 4) || (row.status == 1 || row.status == 2 || row.status == 3)){
        this.$store.dispatch("app/courseBreadcrumb", [this.courseBreadcrumb[0], { label: row.title, packageId: row.packageId, courseId: row.courseId, campId: row.campId, courseType: row.courseType } ]);
        return;
      }
    },
    // 关闭收货地址弹窗
    closeAddress(is,obj){
      this.visibleAddress = false;
      this.item = obj;
      if(is){
        this.initData();
      }
    },
    // 选择开课时间弹窗
    closeOpenCourse(is,obj){
      this.visibleOpenCourse = false;
      this.item = obj;
      if(is){
        this.initData();
      }
    },
    // 合同弹窗
    closeContract(is,obj){
      this.visibleContract = false;
      this.item = obj;
      if(is){
        this.initData();
      }
    },    
  }
}
</script>