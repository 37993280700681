<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="dialog" :close-on-click-modal="false" :before-close="dialogClose" :destroy-on-close="true">
    <div class="dialog-content">
      <div class="header">
        <div class="close pic" @click="close">
          <img src="@/assets/img/icon_014.png" alt="" class="i">
        </div>

        <div class="title">选择开课时间</div>
        <div class="description">请根据自己的日程安排合理选择开课时间</div>
      </div>
      <div class="form">
        <el-form :model="formData" :rules="rules" ref="ruleForm">

          <el-form-item label="" prop="classId">
            <el-radio-group v-model="formData.classId">
              <el-radio v-for="item, ind in optionsKksj" :key="ind" :label="item.classId" :disabled="Boolean(item.fullStatus)">{{ item.date }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item style="margin-bottom: 0; margin-top: 32px;">
            <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">确认提交</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getCouseTime, selectCourseTime } from '@/api/course' // 获取开课时间, 提交开课时间
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      
    },
  },
  watch: {
    visible (newValue){
      this.dialogVisible = newValue;
    },
    data (newValue){
      if(this.dialogVisible){
        getCouseTime({packageId: newValue.packageId}).then(res => {
          if(res.code == 0){
            this.optionsKksj = res.data;
          }
        });
      }
    },
    options (newValue){
      
    },
  },
  data () {
    return {
      formData: {
        classId: '',
      },
      dialogVisible: this.visible,
      rules: {
        classId: [
          { required: true, message: '选择开课时间', trigger: 'change' }
        ],
      },
      optionsKksj: [],
      btnLoading: false,
    }
  },
  mounted() {},
  methods: {
    // 确定 
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let p = selectCourseTime(this.formData);
          p.then(res => {
            this.btnLoading = false;
            if(res.code == 0){
              this.$emit('close', true, {});
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.dialogClose();
            }
          }).catch(err => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭
    close() {
      this.dialogClose();
    },
    // 弹窗关闭前回调
    dialogClose(){
      this.formData = { // 关闭清空弹窗数据
        classId: '',
      },
      this.optionsKksj = [];
      this.$refs['ruleForm'].resetFields(); // 对整个表单进行重置, 初始值
      this.$emit('close', false, {});
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{ display: none; }
::v-deep .el-dialog{
  width: 650px; position: relative;
  background: #FFFFFF; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
  border-radius: 20px; padding: 30px 28px;
  .el-dialog__body{ padding: 0; }
  .header{
    .close{
      position: absolute; right: 22px; top: 18px;
      width: 20px; cursor: pointer;
    }
    .title{
      font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px;
    }
    .description{
      font-size: 14px; color: #808080; line-height: 20px; margin-top: 8px;
    }
  }
  .form{
    margin-top: 36px;
    .el-radio-group{ margin: -24px 0 0 -24px; }
    .el-radio{
      margin: 24px 0 0 24px;
      width: 180px; height: 54px; background: #FBFBFB; border-radius: 8px; border: 1px solid #DFDFDF; text-align: center; line-height: 54px;
      .el-radio__input{ display: none; }
      .el-radio__label{
        padding: 0;
        font-size: 18px; font-weight: 500; color: #474747; 
      }
      &.is-checked{
        position: relative;
        border-color: #FF6315;
        .el-radio__label{
          color: #FF6315;
        }
        &::after{
          content: ''; position: absolute; right: 12px; top: 50%; transform: translateY(-50%);
          width: 18px; height: 12px; background: url(./../../../assets/img/icon_019.png) no-repeat 100%/100%;
        }
      }
      &.is-disabled{
        cursor: no-drop;
        .el-radio__label{
          color: #B4B4B4;
        }
        &::after{
          content: '已满'; position: absolute; right: -1px; top: -1px; width: 40px; height: 18px; background-color: #FF6315; line-height: 18px; font-size: 12px; color: #fff; border-radius: 0 8px 0 8px;
        }
      }
    }
  }
  .confirm{
    padding: 0; display: block; margin: auto;
    width: 350px; height: 54px; background: #FF6315; border-color: #FF6315; 
    font-size: 20px; font-weight: 600; color: #FFFFFF; line-height: 54px; border-radius: 14px;
  }
}
</style>