<template>
  <div class="app-container">
    <div class="x-wrap">
      <side-bar :curMent="curMent"></side-bar>
      <main-container :curMent="curMent"></main-container>
    </div>
  </div>
</template>

<script>
import sideBar from "./sideBar";
import mainContainer from "./mainContainer";
export default {
  data() {
    return {
      curMent: 1,  // 默认菜单: 1 个人信息, 2 我的课程,
    }
  },
  components: {
    sideBar,
    mainContainer
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if(query.curMent){
          if(query.curMent != 1 && query.curMent != 2){
            this.$router.push({query: {curMent: this.curMent}});
          }
          this.curMent = query.curMent;
        } else {
          this.$router.push({query: {curMent: this.curMent}});
        } 
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>