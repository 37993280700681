<template>
  <el-form-item label="" :prop="propName" class="birthday">
    <el-date-picker v-model="value" type="date" placeholder="选择生日" @change="upDataValue" :clearable="false" value-format="yyyy-MM-dd"></el-date-picker>

    <!-- <el-select v-model="value" placeholder="选择性别" @change="upDataValue">
      <el-option v-for="item in options" :key="item.code" :label="item.desc" :value="item.code"></el-option>
    </el-select> -->
  </el-form-item>
</template>

<script>
export default {
  props: {
    birthday: {
      type: String,
      default: ''
    },
    propName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.birthday,
    }
  },
  methods: {
    upDataValue(){
      this.$emit('update:birthday', this.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .birthday{
    margin-bottom: 0;
    
    .el-date-editor{ width: 100%; }
    ::v-deep .el-input__prefix .el-input__icon::before{ font-size: 16px; }

    ::v-deep .el-input__inner{
      height: 50px; line-height: 50px;
      border-radius: 14px; border: 1px solid #E5E5E5;
      font-size: 16px; color: #1B1B1B;
      &:focus{
        border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color:#E5E5E5;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#E5E5E5;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#E5E5E5;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#E5E5E5;
      }
    }
  }
  
</style>