<template>
  <div class="main-container">
    <component :is='componentName[curMent - 1]'/>
  </div>
</template>

<script>
import info from "./info.vue";
import packageT from "./package.vue";
export default {
  props: {
    curMent: {
      type: String | Number,
      default: ''
    },
  },
  components: {
    info,
    packageT,
  },
  data() {
    return {
      componentName: ['info', 'packageT']
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
