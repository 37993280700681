<template>
  <el-form-item label="" :prop="propName" class="areaDropdown">
    <el-cascader v-model="value" placeholder="选择地区" :options="options"
    @change="upDataValue" filterable :props="{value:'text', label: 'text', children: 'children'}"></el-cascader>
  </el-form-item>
</template>

<script>
import { useCascaderAreaData } from '@vant/area-data';
export default {
  props: {
    areaDropdown: {
      type: Array,
      default() {
        return []
      }
    },
    propName: {
      type: String,
      default: ''
    },
    children: { // 默认省市区, 1: 只显示省, 2: 只显示省市
      type: Number | String,
      default: '',
    }
  },
  data() {
    return {
      value: this.areaDropdown,
      // options: require("@/assets/areaData.json"),
      options: useCascaderAreaData(),
    }
  },
  created(){
    this.options = this.filterData(useCascaderAreaData(), this.children);
  },
  methods: {
    upDataValue(){
      this.$emit('update:areaDropdown', this.value);
    },
    filterData(areaData, num){
      if(!num){ return areaData };
      num --;
      areaData.forEach(v => {
        if(num < 1){
          v.children = undefined;
        } else {
          this.filterData(v.children, num);
        }
      });
      return areaData;
    }
  }
}
</script>

<style lang="scss" scoped>
  .areaDropdown{
    margin-bottom: 0;
    ::v-deep .el-cascader .el-input.is-focus .el-input__inner{
      border-color: #FF6315;
    }
    .el-cascader{ width: 100%; }

    ::v-deep .el-input__inner{
      height: 50px; line-height: 50px;
      border-radius: 14px; border: 1px solid #E5E5E5;
      font-size: 16px; color: #1B1B1B;
      &:focus{
        border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color:#E5E5E5;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#E5E5E5;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#E5E5E5;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#E5E5E5;
      }
    }
  }
</style>