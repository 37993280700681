<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="dialog" :close-on-click-modal="false" :before-close="dialogClose" :destroy-on-close="true">
    <div class="dialog-content">
      <div class="header">
        <div class="close pic" @click="close">
          <img src="@/assets/img/icon_014.png" alt="" class="i">
        </div>
        <div class="title" v-if="btnCode == 1">绑定手机号</div>
        <div class="title" v-if="btnCode == 2">更换手机号</div>
      </div>
      <div class="form">
        <el-form :model="formData" :rules="rules" ref="ruleForm">

          <div class="item" v-if="btnCode == 2">
            <div style="width: calc(100% - 15px - 2px); height: 58px; line-height: 58px; background: #F8F8F8; border-radius: 14px; border: 1px solid #E5E5E5; font-size: 18px; color: #717171; padding-left: 15px; margin-bottom: 20px;">{{ mobile }}</div>
          </div>

          <div class="item">
            <mobile :mobile.sync="formData.mobile" :propName="'mobile'" style="width: 100%; margin-bottom: 20px;"></mobile>
          </div>
          
          <div class="item">
            <verification-code :verificationCode.sync="formData.smsCode" :propName="'smsCode'" :mobile="formData.mobile" @verify="verify" style="width: 100%;"></verification-code>
          </div>

          <el-form-item style="margin-bottom: 0; margin-top: 35px;">
            <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">确认</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import mobile from "@/components/Input/mobile";
import verificationCode from "@/components/Input/verificationCode";
import { mapGetters } from "vuex";
import { bindMobile, changeMobile, checkSmsCode } from '@/api/user' // 绑定手机号, 更换手机号, 校验验证码
export default {
  components: {
    mobile, verificationCode
  },
  computed: {
    ...mapGetters([
      'mobile'
    ]),
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      
    },
  },
  data () {
    var checkMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
      if (!value) {
        callback(new Error('输入手机号'));
      } else if (!reg_tel.test(value)) {
        // setTimeout(() => {
          callback(new Error('输入正确的手机号'));
        // }, 500);
      } else {
        callback();
      }
    };
    var checkSmsCode = (rule, value, callback) => {
      var reg_tel = /[^0-9]/g;    // 4为以上数字
      if (!value) {
        callback(new Error('输入验证码'));
      } else if (reg_tel.test(value) || value.length < 4) {
        setTimeout(() => {
          callback(new Error('输入正确的验证码'));
        }, 500);
      } else {
        callback();
      }
    };
    return {
      formData: {
        mobile: "",
        smsCode: "",
      },
      dialogVisible: this.visible,
      rules: {
        mobile: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        smsCode: [
          { required: true, validator: checkSmsCode, trigger: 'blur' }
        ],
      },
      btnLoading: false,
      btnCode: '',  // 1绑定, 2更换, 1设置, 2修改
    }
  },
  mounted() {},
  watch: {
    visible (newValue){
      this.dialogVisible = newValue;
    },
    data (newValue){
      if(this.dialogVisible){
        this.btnCode = newValue.btnCode; 
      }
    },
    options (newValue){
      
    },
  },
  methods: {
    // 确定 
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let p, checkCode;
          if(this.btnCode == 1){
            // 绑定手机号
            p = bindMobile({mobile: this.formData.mobile, smsCode: this.formData.smsCode});
          } else if(this.btnCode == 2){
            // 校验验证码
            checkCode = checkSmsCode({mobile: this.formData.mobile, smsCode: this.formData.smsCode});
          }
          // 更换手机号需要走校验验证码是否正确
          if(checkCode){
            checkCode.then(res => {
              if(res.code == 0){
                // 更换手机号
                p = changeMobile({mobile: this.formData.mobile});
                this.changeMobile(p);
              }
            }).catch(err => {
              console.log(err);
              this.btnLoading = false;
            });
          } else {
            this.changeMobile(p);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 手机号传入后
    changeMobile(p){
      p.then(res => {
        this.btnLoading = false;
        if(res.code == 0){
          this.$store.commit('user/SET_MOBILE', this.formData.mobile);  // 更新手机号
          this.$emit('close', true, {});
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.dialogClose();
        }
      }).catch(err => {
        this.btnLoading = false;
      });
    },
    // 关闭
    close() {
      this.dialogClose();
    },
    // 弹窗关闭前回调
    dialogClose(){
      this.formData = { // 关闭清空弹窗数据
        mobile: '',
        smsCode: '',
      },
      this.$refs['ruleForm'].resetFields(); // 对整个表单进行重置, 初始值
      this.$emit('close', false, {});
    },
    verify() {
      // 手机号校验
      this.$refs['ruleForm'].validateField('mobile');
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{ display: none; }
::v-deep .el-dialog{
  width: 436px; position: relative;
  background: #FFFFFF; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
  border-radius: 20px; padding: 38px 44px 44px;
  .el-dialog__body{ padding: 0; }
  .header{
    .close{
      position: absolute; right: 22px; top: 18px;
      width: 20px; cursor: pointer;
    }
    .title{
      font-size: 20px; font-weight: 600; color: #3C3C3C; line-height: 28px;
    }
  }
  .form{
    margin-top: 20px;
  }
  .confirm{
    padding: 0;
    width: 100%; height: 54px; background: #FF6315; border-color: #FF6315; 
    font-size: 20px; font-weight: 600; color: #FFFFFF; line-height: 54px;
  }
}
</style>