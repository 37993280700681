<template>
  <div class="course-list" v-loading="loading">
    <div class="top">
      <div class="title">
        <div class="tit">{{ courseHead.packageName }}</div>
        <div class="icon pic" v-if="courseHead.isLiveType == 1">
          <img src="@/assets/img/icon_020.png" alt="" class="i">
        </div>
      </div>
      <div class="info-desc">
        <div class="icon-txt">
          <div class="icon pic">
            <img src="@/assets/img/icon_021.png" alt="" class="i">
          </div>
          <div class="txt">课程周期：{{ courseHead.teachingStartTime }}——{{ courseHead.teachingEndTime }}</div>
        </div>
        <div class="icon-txt">
          <div class="icon pic">
            <img src="@/assets/img/icon_022.png" alt="" class="i">
          </div>
          <div class="txt">共{{ courseHead.courseCount }}节｜ <span class="hover">已授{{ courseHead.studyCount }}节</span> </div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="item, ind in courseList" :key="ind">
        <div class="label">• 第{{ numOneToStrOne(ind + 1) }}天</div>
        <!-- 直播课 -->
        <div class="content live" v-if="courseHead.isLiveType == 1">
          <div class="li">
            <div class="text">
              <div class="title">{{ item.liveName }}</div>
              <!-- 等待开始 || 直播中 -->
              <div v-if="item.liveState == 1 || item.liveState == 2" class="live-time">开始时间：{{ formatTime(Number(item.startTimeStamp)) }}</div>
              <!-- 观看回放 -->
              <div v-if="item.liveState == 3" class="live-time">直播时长：{{ item.showTime }}</div>
            </div>
            <el-popover placement="left" width="300" trigger="click" popper-class="app-qr-code-pop-up">
              <downloadAppPopUp></downloadAppPopUp>
              <div class="btn btn1" slot="reference" v-if="item.liveState == 3">观看回放</div>
              <div class="btn btn2" slot="reference" v-if="item.liveState == 2">
                <div class="pic">
                  <img src="@/assets/img/icon_024.png" alt="" class="i">
                </div>
                直播中
              </div>
              <div class="btn btn3" slot="reference" v-if="item.liveState == 1">等待开始</div>
            </el-popover>
          </div>
        </div>
        <!-- 图文课 -->
        <div class="content image-text" v-else>
          <div class="li" v-for="ite, i in item.sectionList" :key="i">
            <div class="title">{{ ite.sectionName }}</div>
            <el-popover placement="left" width="300" trigger="click" popper-class="app-qr-code-pop-up">
              <downloadAppPopUp></downloadAppPopUp>
              <div class="btn btn1" slot="reference" v-if="ite.lockStatus == 0">未解锁</div>
              <template v-else>
                <div class="btn btn3" slot="reference" v-if="ite.hasStudy">已学习</div>
                <div class="btn btn2" slot="reference" v-if="!ite.hasStudy">去学习</div>
              </template>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="no-more">没有更多了~</div>
  </div>
</template>

<script>
import { objectDeleteKey, numOneToStrOne, formatTime } from "@/utils"
import { mapGetters } from "vuex";
import downloadAppPopUp from "@/components/downloadAppPopUp";
import { getXbCourseInfo } from "@/api/course"
export default {
  components: {
    downloadAppPopUp
  },
  computed: {
    ...mapGetters([
      'courseBreadcrumb'
    ]),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    courseBreadcrumb: {
      handler (newValue){
        console.log(newValue);
        if(newValue.length != 2) return;
        this.loading = true;
        let params = newValue[newValue.length - 1];
        this.paramsCX.packageId = params.packageId;
        this.paramsCX.campId = params.campId;
        this.paramsCX.courseId = params.courseId;

        getXbCourseInfo(objectDeleteKey(this.paramsCX)).then(res => {
          if(res.code == 0) {
            this.courseHead = res.data.courseHead;
            this.courseList = this.courseHead.isLiveType == 1 ? res.data.XbCourseBos.dataList : res.data.XbCourseBos.chapterList;

            this.loading = false;
          }
        });
        
      },
      deep: true,
      immediate: true,
    },
    data (newValue){
      
    },
  },
  data() {
    return {
      paramsCX: {
        packageId: '',
        campId: '',
        courseId: '',
      },  // 查询所用参数
      loading: false,
      courseHead: {}, // 课程头部信息
      courseList: [],
      totalCount: 0,
    }
  },
  created (){
    console.log('courseListXB');
  },
  methods: {
    numOneToStrOne(num) {
      return numOneToStrOne(num);
    },
    formatTime(num) {
      return formatTime(num);
    },
    
  }
  
}
</script>

<style lang="scss" scoped>
@import "./courseListXB.scss";
</style>