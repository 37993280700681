<template>
  <div class="breadcrumb">
    <div class="item" :class="{cur: ind == courseBreadcrumb.length - 1}" v-for="item, ind in courseBreadcrumb" :key="ind">
      <div class="label" @click="jump(item, ind)">{{ item.label }}</div>
      <div class="slash">/</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      'courseBreadcrumb'
    ]),
  },
  data () {
    return {
      
    }
  },
  methods: {
    jump(item, ind) {
      if(ind == this.courseBreadcrumb.length - 1) { return; } // 点击最后一个不跳转

      this.$store.dispatch("app/courseBreadcrumb", this.courseBreadcrumb.splice(0, ind + 1));
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb{
  padding: 10px 0;
  .item, .label, .slash{
    display: inline-block; vertical-align: middle;
  }
  .item{
    .label, .slash{
      font-size: 14px; color: #ABABAB;
    }
    .label{
      cursor: pointer;
    }
    .slash{
      padding: 0 10px;
    }
    &:hover{
      .label{
        color: #FF6315;
      }
    }
    &.cur{
      .label{
        color: #656565;
        cursor: initial;
      }
      .slash{
        display: none;
      }
    }
  }
}
</style>