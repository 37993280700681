<template>
  <el-form-item label="" :prop="propName" ref="nickName" class="nickName">
    <el-input v-model="value" :placeholder="placeholderTxt" @blur="upDataValue">

    </el-input>
  </el-form-item>
</template>

<script>
export default {
  props: {
    nickName: {
      type: String,
      default: ''
    },
    propName: {
      type: String,
      default: ''
    },
    placeholderTxt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.nickName
    }
  },
  methods: {
    upDataValue(){
      this.$emit('update:nickName', this.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .nickName{
    margin-bottom: 0;
    ::v-deep .el-input__inner{
      height: 50px; line-height: 50px;
      border-radius: 14px; border: 1px solid #E5E5E5;
      font-size: 16px; color: #1B1B1B;
      &:focus{
        border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color:#E5E5E5;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#E5E5E5;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#E5E5E5;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#E5E5E5;
      }
    }
  }
</style>